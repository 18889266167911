









































import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import Search from '@/components/shared/Search.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import ResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import Vue, { VueConstructor } from 'vue';
import { useEmployeesStore } from '@/store/employees-store';
import { mapStores } from 'pinia';
import { Employee } from '@/model/employee';
import {
  EmployeePanel,
  EMPLOYEES_PANELS_LAYOUT,
  useUiStore
} from '@/store/ui-store';
import ConfigListElement from '@/components/shared/ConfigListElement.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ResourceConfigMixin>
>).extend({
  components: {
    LoadingIndicator,
    Search,
    EmptyDataset,
    ConfigListElement
  },
  mixins: [ResourceConfigMixin],
  data() {
    return {
      searchTerms: '',
      EmployeePanel: EmployeePanel
    };
  },
  computed: {
    ...mapStores(useEmployeesStore, useUiStore),
    isFetchingEmployees(): boolean {
      return this.employeesStore.fetching;
    },
    employees(): Employee[] {
      return this.employeesStore.entities;
    },
    currentEmployee(): Employee {
      return this.employeesStore.currentEntity;
    }
  },
  methods: {
    getEmployeesFiltered(employees: Employee[]): Employee[] {
      if (this.searchTerms) {
        return employees.filter(employee => {
          const splitVal = this.searchTerms.split(' ');
          const joinedSplitVal = splitVal.join('.*');
          const regex = new RegExp(joinedSplitVal, 'gi');
          return regex.test(`${employee.firstname}${employee.lastname}`);
        });
      }
      return employees;
    },

    editEmployee(employee: Employee) {
      this.uiStore.openPanel(
        EMPLOYEES_PANELS_LAYOUT,
        EmployeePanel.EDIT_EMPLOYEE
      );
      this.employeesStore.setCurrentEntity(employee);
    }
  }
});
