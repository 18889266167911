


















































import EmployeesList from '@/components/employees/EmployeesList.vue';
import EditEmployee from '@/components/employees/EditEmployee.vue';
import AddWorkplace from '@/components/workplaces/AddWorkplace.vue';
import AddService from '@/components/services/AddService.vue';
import AddEmployee from '@/components/employees/AddEmployee.vue';
import Vue from 'vue';
import {
  EmployeePanel,
  EMPLOYEES_PANELS_LAYOUT,
  Panel,
  useUiStore
} from '@/store/ui-store';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';
import { useUsersStore } from '@/store/users-store';
import { Employee } from '@/model/employee';
import PlainButton from '@/components/shared/PlainButton.vue';

export default Vue.extend({
  components: {
    AddEmployee,
    AddService,
    EditEmployee,
    EmployeesList,
    AddWorkplace,
    PlainButton
  },
  data() {
    return {
      EMPLOYEE_PANEL: EmployeePanel
    };
  },
  computed: {
    ...mapStores(useEmployeesStore, useUiStore, useUsersStore),
    isFetchingEmployees(): boolean {
      return this.employeesStore.fetching;
    },
    employees(): Employee[] {
      return this.employeesStore.entities;
    },
    currentEmployee(): Employee {
      return this.employeesStore.currentEntity;
    },
    openedPanels(): string[] {
      return this.uiStore.openedPanels;
    },
    currentPanelIndex(): number {
      return this.uiStore.currentPanelIndex;
    },
    currentPanel(): string {
      return this.uiStore.currentPanel;
    },
    vendorId(): number {
      return this.usersStore.loggedInVendorId as number;
    },
    isAdmin(): boolean {
      return this.usersStore.isAdmin as boolean;
    }
  },
  created() {
    this.employeesStore.findAll(null, true, false);
    this.uiStore.openPanel(
      EMPLOYEES_PANELS_LAYOUT,
      EmployeePanel.EMPLOYEES_LIST
    );
  },
  methods: {
    isPanelOpened(panel: Panel): boolean {
      return this.uiStore.isPanelOpened(panel);
    },
    addEmployee() {
      this.employeesStore.unsetCurrentEntity();
      this.uiStore.openPanel(
        EMPLOYEES_PANELS_LAYOUT,
        EmployeePanel.ADD_EMPLOYEE
      );
    }
  }
});
