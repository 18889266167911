

















































import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import EmployeeForm from '@/components/employees/EmployeeForm.vue';
import BaseEmployeeMixin from '@/components/employees/BaseEmployeeMixin';
import employeesService from '@/services/employee.service';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import ResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import CheckEventsConflictsModal from '@/components/timetables/CheckEventsConflictsModal.vue';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { AlertType, EmployeePanel, useUiStore } from '@/store/ui-store';
import { useEmployeesStore } from '@/store/employees-store';
import { Employee } from '@/model/employee';
import { Service } from '@/model/service';
import { Workplace } from '@/model/workplace';
import { Collection } from '@/store/common/collections';
import { useTimetablesStore } from '@/store/timetables-store';
import { useTimetableExceptionsStore } from '@/store/timetable-exceptions-store';
import { useUsersStore } from '@/store/users-store';
import PlainButton from '@/components/shared/PlainButton.vue';

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof BaseEmployeeMixin> &
    InstanceType<typeof ResourceConfigMixin>
>).extend({
  components: {
    LoadingIndicator,
    CheckEventsConflictsModal,
    EmployeeForm,
    BackButtonTitle,
    PlainButton
  },
  mixins: [BaseEmployeeMixin, ResourceConfigMixin],
  computed: {
    ...mapStores(
      useUiStore,
      useEmployeesStore,
      useTimetablesStore,
      useTimetableExceptionsStore,
      useUsersStore
    ),
    loading(): boolean {
      return this.employeesStore.fetching;
    },
    loggedInEmployeeId(): number {
      return this.usersStore.loggedInEmployeeId;
    }
  },
  watch: {
    currentEmployee: function() {
      this.findTimetables();
    }
  },
  methods: {
    created() {
      this.findTimetables();
    },
    findTimetables() {
      if (this.currentEmployee?.id) {
        this.timetablesStore.findAll(this.currentEmployee.id, true);
        this.timetableExceptionsStore.findAll(this.currentEmployee.id, true);
      }
    },
    closeCurrentPanel() {
      this.uiStore.closePanel(EmployeePanel.EDIT_EMPLOYEE);
      this.employeesStore.unsetCurrentEntity();
    },
    openDeleteEmployeeModal() {
      this.$bvModal.show('check-events-conflicts-modal');
    },
    async verifyDeleteEmployee() {
      await employeesService.verifyDeleteEmployee(this.currentEmployee.id);
    },
    async deleteEmployee() {
      return this.employeesStore.delete(this.currentEmployee);
    },
    async employeeDeleted() {
      this.closeCurrentPanel();
      this.uiStore.alert(this.$t('toast.employeeDeleted'), AlertType.SUCCESS);
    },
    async editEmployee({
      employee,
      preferredServices,
      notPreferredServices,
      preferredWorkplaces,
      notPreferredWorkplaces
    }: {
      employee: Employee;
      preferredServices: Service[];
      notPreferredServices: Service[];
      preferredWorkplaces: Workplace[];
      notPreferredWorkplaces: Workplace[];
    }) {
      const employeeFromApi = this.getEmployeeForApi({
        employee,
        preferredServices,
        notPreferredServices,
        preferredWorkplaces,
        notPreferredWorkplaces
      });
      await this.employeesStore.edit(employeeFromApi, [
        Collection.WORKPLACES,
        Collection.SERVICES
      ]);
      if (employee.id === this.loggedInEmployeeId) {
        // On met à jour également les infos de l'utilisateur courant
        await this.usersStore.getUser(this.usersStore.loggedInUserId);
      }
    }
  }
});
